.projectgrid {
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 5rem;
	animation: 
		var(--in-bottom) 2s forwards,
		var(--opacity) 3s;
	grid-auto-rows: min-content;
}

.gridItem {
	height: 100%;
}

@media (max-width: 1000px) {
	.projectgrid {
		grid-template-columns: 1fr;

	}
}