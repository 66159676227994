.colourbtn {
	border-radius: 5px;
	height: 35px;
	cursor: pointer;
	border: 1px solid black;
	padding: 10px;
	margin: 2px 5px;
	filter: grayscale(1);
	transition: linear .2s;

	&:hover, &:focus {
		filter: grayscale(0);
	}
}