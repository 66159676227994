.techstacks {
	width: 100%;
	margin: 4rem auto;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 25px;
	justify-content: center;
	animation:
		var(--in-bottom) 2s forwards,
		var(--opacity) 3s;
}

$background-colour: var(--accent-colour);

.icon {
	padding: 15px;
	margin: 0px 10px;
	transition: ease all .2s;
	border-radius: 25px;
	// outline: 3px var(--accent-colour) solid;
 
	cursor: help;
	filter: grayscale(100%);
}

.icon:hover {
	transform: scale(1.15);
	filter: 
		grayscale(0)
		drop-shadow(1px 2px 4px var(--accent-colour));
}

@media (max-width: 450px) {
	.techstacks {
		width: 90%;
	}

	.icon {
		margin: 0px;
	}
}