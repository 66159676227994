.profile {
	h1 {
		font-size: 4em;
		font-weight: 400;
		margin: auto;
		animation: var(--in-top-bounce) 3s forwards;
		color: var(--heading-text-colour);

		&::after {
			position: relative;
			display: block;
			content: '';
			height: 3px;
			background-color: var(--accent-colour);
			margin: 15px;
			width: 50%;
			animation: var(--in-left) 3s forwards;
		}
	}

	h2 {
		font-size: 2em;
		font-weight: 400;
		margin: auto;
		position: relative;
		animation: 
			var(--opacity) 2s, 
			var(--in-right) 3s forwards;
		color: var(--heading-text-colour);
		span {
			color: var(--accent-colour);
		}
	}

	h3 {
		position: relative;
		margin: 5px 0px 0px;
		font-weight: 100;
		opacity: 0;
		animation: var(--opacity-half) .5s forwards;
		animation-delay: 3s;
	}

	p {
		font-size: 1.5em;
		font-weight: 300;
		width: 70%;
		position: relative;
		animation: 
			var(--opacity) 2s, 
			var(--in-right) 3s forwards;
	}

	&::after {
		position: relative;
		display: block;
		content: '';
		height: 3px;
		background-color: var(--accent-colour);
		margin: 15px;
		width: 50%;
		animation: var(--in-left) 3s forwards;
	}
}


@media (max-width: 450px) {
	.profile {
		width: 100%;

		p {
			width: 95%;
		}
	}
}