.footnav {
	position: fixed;
	bottom: 0;
	border-top: 1px solid var(--accent-colour);
	box-shadow: var(--box-shadow);
	background-color: var(--background-colour);
	width: 100%;
	margin: auto;
	height: 4rem;
}

.footnav__links {
	list-style: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: auto;
	padding-left: 0;

	li {
		// display: inline-block;
		
		a{
			display: block;
			background-color: #fff;
			padding: 15px;
			text-decoration: none;

		}
	}
}

p {
	position: fixed;
	right: 0;
	bottom: 0;
	margin: 2px 5px;
}