.navbar {
	position: relative;
	display: flex;
	z-index: 9999;

	max-height: 4rem;
	height: 4rem;
	width: 100%;
	padding-left: 100px;

	background-color: var(--background-colour);
	border-bottom: 1px var(--accent-colour) solid;
	box-shadow: 0px 15px 15px rgba(0, 0, 0, .3);
}

.navbar__brand {
	position: relative;
	z-index: 20;
	min-height: 5rem;
	min-width: 5rem;
	margin: -2px;
	-webkit-user-drag: none;

	background-color: var(--background-colour);
	border: 1px solid var(--accent-colour);
	box-shadow: var(--box-shadow);

	animation: var(--in-top-delayed) 4s;

	h1 {
		display: block;
		margin: auto;
		height: 100%;
		text-align: center;
		line-height: 250%;
		user-select: none;
		text-decoration: none;
		font-size: 2rem;
	}

	a {
		text-decoration: none;
		color: var(--accent-colour);

		&:active {
			color: var(--text-colour);
		}
	}

	&:hover {
		border-color: #fff;
	}
}

.navbar__links {
	display: flex;
	text-align: center;
	margin-top: auto;
	height: 100%;
	width: 100%;
	align-items: center;
	padding: 0;
	transition: var(--transition);
	position: relative;

	li {
		display: inline-block;
		width: max-content;
		line-height: 4rem;

		border-right: 1px solid var(--accent-colour);
		background-color: var(--background-colour);
		transition: ease-out .3s all;
		transition: linear 5s transform;
		transition: ease .1s background-color;
		position: relative;
		animation: var(--in-top-delayed);

		a {
			// box-sizing: content-box;
			display: block;
			text-decoration: none;
			height: max-content;
			color: var(--text-colour);
			font-size: 1.5em;
			padding: 0px 2rem;

			.navIcon {
				position: relative;
				top: 5px;
			}

			span {
				padding-left: 5px;
			}
		}

		&:hover {
			color: var(--heading-text-colour);
			background-color: var(--accent-colour);
			border-bottom: solid 2px var(--text-colour);
		}

		&:first-child {
			border-left: 1px solid var(--accent-colour);
		}
	}

	@for $i from 1 to 5 {
		li:nth-child(n + #{$i}) {
			animation-duration: calc($i / 3.5) + 2s;
		}
	}
}



@media (max-width: 450px) {
	.navbar {
		padding-left: 10px;
	}

	.navbar__links {
		span {
			display: none;
		}
	}
}