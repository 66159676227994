:root {
	--opacity: Opacity;
	--opacity-half: OpacityHalf;
	--visibility: Visibility;
	--in-top-delayed: InFromTopDelayed;
	--in-top-bounce: InFromTopBounce;
	--in-right: InFromRight;
	--in-bottom: InFromBottom;
	--in-left: InFromLeft;
}

.banner {
	content: ' ';
	position: absolute;
	background-color: var(--accent-colour);
	height: 10%;
	width: 150%;
	rotate: 20deg;
	right: -50%;
	top: 5em;
	z-index: -5;
	filter: brightness(70%);
}


@keyframes Opacity {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
@keyframes OpacityHalf {
	from {
		opacity: 0;
	}

	to {
		opacity: .5;
	}
}

@keyframes Visibility {
	from {
		visibility: hidden;
	}

	to {
		visibility: visible;
	}
}

@keyframes InFromTopDelayed {
	0% {
		top: -100px
	}

	60% {
		top: -100px
	}

	100% {
		top: 0
	}
}

@keyframes InFromTopBounce {
	0% {
		transform: translateY(-200%)
	}

	60% {
		transform: translateY(5%)
	}

	100% {
		transform: translateY(0%)
	}
}


@keyframes InFromRight {
	from {
		right: -100px
	}

	to {
		right: 0px
	}
}

@keyframes InFromBottom {
	from {
		transform: translateY(50%);
	}

	to {
		transform: translateY(0%);
	}
}

@keyframes InFromLeft {
	from {
		left: -1000px
	}

	to {
		left: 0px
	}
}