@font-face {
	font-family: 'Dosis';
	src: local('Dosis'), url(./assets/fonts/Dosis-VariableFont_wght.ttf) format('truetype');
}

/* * {
	box-sizing: border-box;
} */


body {
	margin: 0;
	font-family: Dosis, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	letter-spacing: 1.5px;
	background-color: var(--background-colour);
	color: var(--text-colour);
	overflow: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

:root {
	/* colours */
	--background-colour: #191919;
	--secondary-colour: #333;
	--accent-colour: rgb(255, 136, 0);
	--heading-text-colour: #fff;
	--text-colour: rgba(255, 255, 255, 0.85);
	--overlay-colour: rgba(255, 255, 255, 0.7);

	/* Effects */
	--box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.65);
	--transition: ease 0.1s all;

	/* media queries */
	--breakpoint-phone: 400px;
	--breakpoint-tablet: 768px;
	--breakpoint-laptop: 1378px;
	--breakpoint-4k: 2000px;

}

.wrapper {
	padding: .6rem;
	overflow-x: hidden;
	overflow-y: auto;
	height: 84vh;
	width: 95vw;
	margin: auto;
	margin-bottom: 2rem;
	margin-top: 2px;
	animation: var(--opacity) 3s forwards;

}

@media (max-width: 450px) {
	html {
		max-height: 90vh;
	}

	.wrapper {
		width: 99vw;
		padding: 0;
		margin-bottom: 0;
	}
}

/* Width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: var(--secondary-colour);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--accent-colour);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--text-colour);
}