.project {
	margin: auto;
	width: 100%;
}

.project__showcase {
	border: 4px solid var(--accent-colour);
	display: flex;
	flex-direction: column;
}

.project__image {
	height: 350px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		overflow: hidden;
	}
}

.project__content {
	h1 {
		margin: 2px 0px;
		letter-spacing: 3px;
		color: var(--heading-text-colour);
	}

	h3 {
		margin: 5px 0px;
		letter-spacing: 2px;
		font-size: 1.3em;
	}

	.content {
		margin-top: 2rem;
		padding-right: 15px;
		font-size: 1.4em;

		br {
			display: block;
			padding: 5px;
			content: '';
		}

		div {
			width: 95%;
			margin-left: 3rem;
		}
	}

	a {
		color: var(--text-color);
	}

	.goToProjectBtn {
		margin: 20px 0px;

	}
}

.project__controls {
	border-top: 3px solid var(--accent-colour);
	display: flex;
	cursor: pointer;

	a,
	button {
		transition: var(--transition);
		color: var(--text-color);
		width: 100%;
	}
}

.progress {
	position: fixed;
	transform: translate(2%, 10%);
	display: flex !important;
	justify-content: flex-start;
	align-items: center;
	height: 2em;
	width: 120px;
	color: var(--text-color);
	background-color: var(--background-colour);
	border-right: 3px solid var(--accent-colour);
	opacity: .6;

	div {
		height: 15px;
		width: 15px;
		margin: 0px 5px 5px;

		.progress_beeper {
			display: inline-block;
			margin: 5px;
			vertical-align: middle;
			background-color: red;
			border-radius: 25px;
			animation: beep 5s infinite;
			// outline: 1px solid black;
		}
	}
}

@keyframes beep {
	0% {
		padding: -20px;
	}

	50% {
		padding: 5px;
	}

	100% {
		padding: -20px;
	}
}